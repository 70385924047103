import { ref, watch } from 'vue';

export const useSearchPathSuffix = () => {
  const route = useRoute();

  const suffix = ref<number>(Math.floor(Math.random() * 1000000));

  const getPathWithSuffix = (path: string): string => {
    // suffix 붙여줘야 같은 path 도 이동함.
    if (path.includes('?')) {
      return path + '&ref=' + suffix.value;
    } else {
      return path + '?ref=' + suffix.value;
    }
  };

  watch(
    () => route.fullPath,
    () => {
      // 페이지 진입시마다 suffix 변경
      suffix.value = Math.floor(Math.random() * 1000000);
    },
  );

  return getPathWithSuffix;
};
