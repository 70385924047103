<script setup lang="ts">
import { useMobile } from '~/composables/useMobile';

import Tracker, { type ClickLoginEventProps } from '~/libs/Tracker';

import { useCommonStore } from '~/services/common';
import { useCommunityStore } from '~/services/community';
import { useUserAuthStore } from '~/services/userAuth';

const communityStore = useCommunityStore();
const userAuthStore = useUserAuthStore();
const commonStore = useCommonStore();
const route = useRoute();
const router = useRouter();
const { isDesktop } = useMobile();

const props = withDefaults(
  defineProps<{
    isShownDepthToggle: boolean;
  }>(),
  {
    isShownDepthToggle: false,
  },
);

const topbtnActive = computed(() => {
  return isDesktop.value ? !props.isShownDepthToggle : !!route.path.match(/\/community\/(\d+)/);
});
const tabParam = computed(() => (route.query.tab as string) ?? '');
const subTabParam = computed(() => (route.query.subTab as string) ?? '');
const showWriteBtn = computed(() => {
  return !props.isShownDepthToggle && !!route.path.match(/\/community/);
});

const communityBoards = await useCommunityBoards();

const currentBoardName = computed(() => {
  const filteredBoards: BoardContent[] = communityBoards.value.filter(
    (board) => `${board.boardCategoryCd}` === (tabParam.value as string),
  );

  if (!filteredBoards.length) {
    return '';
  }

  if (subTabParam.value !== '') {
    const filteredBoardWithSubTab = filteredBoards.find((board) => `${board.boardSeq}` === subTabParam.value);
    return filteredBoardWithSubTab?.boardName;
  }

  return filteredBoards[0].boardName;
});

const onClickWrite = async () => {
  const options: {
    path: string;
    query: {
      boardSeq: string;
    };
  } = { path: '/community/form', query: { boardSeq: subTabParam.value } };
  const boardName = currentBoardName.value ?? '';
  const gnbName = communityStore.setMainBoardName(tabParam.value, boardName);

  if (!(await $authAlert())) {
    const eventParams: ClickLoginEventProps = {
      boardId: subTabParam.value as string,
      boardName,
      gnbName,
      gnbId: tabParam.value,
      boardContentsId: null,
      boardContentsTitle: '',
      location: 'writing',
      text: '글쓰기',
      pageName: 'community',
      url: process.client ? window.location.href : '',
      path: route.fullPath,
    };
    Tracker['Click Login Button'](eventParams);
    return;
  }

  // 로그인 체크 후 계정 차단 체크 하도록 수정
  const data = await userAuthStore.fetchUserInfo();
  if (data.data.items.blockYn === 'Y') {
    return $alert('계정이 차단되어 서비스 이용이 불가합니다.');
  }
  router.push(options);
};
</script>

<template>
  <ClientOnly v-if="showWriteBtn">
    <Teleport to="#app-footer">
      <div class="button-wrapper">
        <Button rounded @click="onClickWrite">
          <template #default>
            <PencilSimpleLine class="write-button-pencil-icon" />
            글쓰기
          </template>
        </Button>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.button-wrapper {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999;
  .p-button {
    height: 50px;
    padding: 16px 24px 16px 20px;
  }
  @media screen and (max-width: $md) {
    right: 16px;
    bottom: 82px;
    .p-button {
      font-size: 14px;
      height: 40px;
      padding: 10px 16px 10px 16px;
    }
  }
}
.write-button-pencil-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  stroke: #ffffff;
  stroke-width: 1.5px;
  color: transparent;

  @media screen and (max-width: $md) {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}
</style>
