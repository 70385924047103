<script setup lang="ts">
import XCircleIcon from '~/components/common/Icons/XCircleIcon.vue';

defineProps<{ to: string }>();
const emit = defineEmits<{ (e: 'remove'): void }>();
</script>

<template>
  <Tag
    class="inline-flex items-center gap-1 bg-transparent text-neutral-1000 p-0 cursor-pointer overflow-hidden max-w-43 md:max-w-full"
    role="button">
    <NuxtLink :to="to" class="overflow-hidden grow">
      <p
        class="text-base md:text-sm text-neutral-1000 font-medium leading-5 whitespace-nowrap text-ellipsis overflow-hidden w-full">
        <slot />
      </p>
    </NuxtLink>

    <XCircleIcon role="button" class="w-5 h-5 md:w-4 md:h-4 cursor-pointer shrink-0" @click.stop="emit('remove')" />
  </Tag>
</template>
