<script setup lang="ts">
import { useSearchStore } from '../store';
import { useSearchPathSuffix } from '../utils/useSearchPathSuffix';
import PopularSearchItem from './PopularSearchItem.vue';

const searchStore = useSearchStore();
const { isDesktop } = useMobile();

const emit = defineEmits<{ (e: 'click:item', label: string): void }>();

const getPathWithSuffix = useSearchPathSuffix();

onMounted(() => {
  searchStore.fetchPopularSearches({ pageNo: 1, pageSize: 10 });
});
</script>

<template>
  <div>
    <p class="text-sm font-bold text-neutral-400">인기 검색어</p>
    <div class="flex flex-col mt-4" :class="{ 'gap-3': isDesktop, 'gap-4': !isDesktop }">
      <CustomLink
        v-for="(item, index) in searchStore.popularSearches"
        :key="item.keyword + String(index)"
        :to="getPathWithSuffix(`/search/${encodeURIComponent(item.keyword)}`)"
        @click="emit('click:item', item.keyword)"
        @auxclick="emit('click:item', item.keyword)">
        <PopularSearchItem :item="item" class="cursor-pointer" />
      </CustomLink>
    </div>
  </div>
</template>
