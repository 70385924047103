<script setup lang="ts">
import { useCommonStore } from '~/services/common';
import RecentSearchKeywordTag from '~/services/search/components/RecentSearchKeywordTag.vue';

import { useSearchPathSuffix } from '../utils/useSearchPathSuffix';

const emit = defineEmits<{ (e: 'click:tag', label: string): void }>();

const getPathWithSuffix = useSearchPathSuffix();

const { isDesktop } = useMobile();
const commonStore = useCommonStore();
</script>

<template>
  <div>
    <p class="text-sm font-bold text-neutral-400">최근 검색어</p>
    <div class="flex gap-3 mt-4" :class="{ 'flex-col items-start': isDesktop, 'flex-wrap': !isDesktop }">
      <RecentSearchKeywordTag
        v-for="(label, index) in commonStore.searchKeywords"
        :key="label + String(index)"
        :to="getPathWithSuffix(`/search/${encodeURIComponent(label)}`)"
        @click="emit('click:tag', label)"
        @auxclick="emit('click:tag', label)"
        @remove="commonStore.removeSearchKeyword(label)">
        {{ label }}
      </RecentSearchKeywordTag>
    </div>
  </div>
</template>
