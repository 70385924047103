<script setup lang="ts">
import { useMobile } from '~/composables/useMobile';

import ChannelService from '~/libs/ChannelService.js';

import CommunityWriteArticleButton from '~/services/community/component/main/button/CommunityWriteArticleButton.vue';

const route = useRoute();
const { isDesktop } = useMobile();

const showCommunityWriteBtn = computed(() => {
  return !props.isShownDepthToggle && route.path === '/community';
});

const props = withDefaults(
  defineProps<{
    isShownDepthToggle: boolean;
  }>(),
  {
    isShownDepthToggle: false,
  },
);

const onInquiry = () => {
  ChannelService.showMessenger();
};

const certificateUrl =
  'https://cdn.weolbu.com/certificate/%E1%84%91%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A2%E1%86%BC%E1%84%80%E1%85%AD%E1%84%8B%E1%85%B2%E1%86%A8%E1%84%89%E1%85%B5%E1%84%89%E1%85%A5%E1%86%AF%20%E1%84%89%E1%85%B5%E1%86%AB%E1%84%80%E1%85%A9%E1%84%8C%E1%85%B3%E1%86%BC.pdf';
</script>

<template>
  <ClientOnly>
    <div class="wb-footer">
      <div class="wb-inner">
        <footer>
          <div class="wb-footer__top">
            <div class="wb-footer__link">
              <ul>
                <li><NuxtLink to="/footer/notice">공지사항</NuxtLink></li>
                <li><NuxtLink to="/footer/terms">서비스 이용약관</NuxtLink></li>

                <li><NuxtLink to="/footer/faq">자주 묻는 질문</NuxtLink></li>
              </ul>
              <ul>
                <li><NuxtLink to="/footer/privacy">개인정보처리방침</NuxtLink></li>
                <li><NuxtLink to="/footer/copyright">저작권 안내</NuxtLink></li>
                <li>
                  <NuxtLink
                    to="https://career.weolbu.com/?utm_source=weolbu_shop&utm_medium=bottom_keyword"
                    target="_blank"
                    >채용</NuxtLink
                  >
                </li>
              </ul>
              <ul>
                <li>
                  <NuxtLink to="https://blog.naver.com/weolbu" target="_blank" class="wb-footer__link--naver"
                    >네이버 블로그</NuxtLink
                  >
                </li>
                <li>
                  <NuxtLink
                    to="https://www.youtube.com/channel/UCDSj40X9FFUAnx1nv7gQhcA"
                    target="_blank"
                    class="wb-footer__link--youtube"
                    >Youtube</NuxtLink
                  >
                </li>
                <li>
                  <NuxtLink
                    to="https://docs.google.com/forms/d/e/1FAIpQLScfpPlTQVkq1HG6qqnOvc2AJEFFu1mqrS61KamxMDR57mxpsA/viewform"
                    target="_blank"
                    class="wb-footer__link--profile"
                    >강사 지원하기</NuxtLink
                  >
                </li>
              </ul>
              <ul>
                <li>
                  <NuxtLink to="https://cafe.naver.com/wecando7" target="_blank" class="wb-footer__link--naver"
                    >네이버 카페</NuxtLink
                  >
                </li>
                <li>
                  <NuxtLink
                    to="https://www.instagram.com/weolbu_official/"
                    target="_blank"
                    class="wb-footer__link--instagram"
                    >인스타그램</NuxtLink
                  >
                </li>
                <li>
                  <NuxtLink to="https://b2b.weolbu.com" target="_blank">비즈니스/제휴 문의</NuxtLink>
                </li>
              </ul>
            </div>

            <div class="wb-footer__customer">
              <div class="wb-footer__customer-button">
                <!-- ChannelService.showMessenger(); -->
                <Button label="문의하기" severity="secondary" size="small" @click="onInquiry" />
              </div>
              <div class="wb-footer__customer-text">
                <strong>평일 10:00 - 18:00</strong> 점심시간 12 -13시
                <hr />
                주말 및 공휴일 제외
              </div>
            </div>
          </div>

          <div class="wb-footer__info">
            <div class="wb-footer__info-text">
              <span>월급쟁이부자들(주)</span>
              <span>대표 이정환</span>
              <div class="business-number-container">
                <span>사업자등록번호 321-86-00842</span>
                <span
                  ><a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=3218600842" target="_blank"
                    ><strong>사업자정보확인</strong>
                  </a>
                </span>
              </div>
              <div class="business-number-container">
                <hr v-if="isDesktop" />
                <span>통신판매신고 번호 2022-서울서초-2692</span>
              </div>
            </div>
            <address>
              <p>사무실 서울시 서초구 사임당로 143, 크로스143타워 2층</p>
              <hr />
              <p>교육장 서울시 강남구 역삼로 134, MS빌딩 2층</p>
              <hr />

              <p>
                개인정보관리책임자 정동그라미
                <a :href="certificateUrl" target="_blank"><strong>원격평생교육원:(제 원-639호)</strong></a>
              </p>
              <span></span>
            </address>
          </div>
        </footer>
        <div class="wb-footer__menu">
          <NuxtLink class="wb-footer__top">
            <p>위로</p>
          </NuxtLink>
          <NuxtLink class="wb-footer__question" @click="onInquiry">
            <p>문의하기</p>
          </NuxtLink>
        </div>
      </div>
    </div>

    <CommunityWriteArticleButton v-if="showCommunityWriteBtn" />
  </ClientOnly>
</template>

<style lang="scss" scoped>
.wb-footer__info-text {
  display: flex;
  @media screen and (max-width: $mobile) {
    display: block;
    span {
      margin-right: 8px;
    }
  }

  .business-number-container {
    display: flex;

    span {
      margin-right: 8px;
    }

    @media screen and (max-width: $mobile) {
      display: block;
      margin: 8px 0 0 0;
    }
  }
}
</style>
