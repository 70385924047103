import axios, { AxiosError } from 'axios';

import { type PaginationOptions } from '~/types/global';

import { type BoardCount } from '../community';
import { type UserInfoSearchedByNickname, userGnbService } from '../gnb';
import { type DisplayProduct } from '../main';
import { searchService } from './service';
import { type CommunitySearchDocument, type PopularSearchItem, SearchOrderType } from './type';

interface State {
  /** 인기검색어 */
  popularSearches: PopularSearchItem[];
  /** 클래스 검색결과 */
  productSearchResults: {
    items: DisplayProduct[];
    pageNo: number;
    totalCount: number;
    isFetching: boolean;
    isError: boolean;
    errorMessage: string;
    hasMore: boolean;
  };
  /** 닉네임 검색결과 */
  nicknameSearchResults: {
    items: UserInfoSearchedByNickname[];
    pageNo: number;
    totalCount: number;
    isFetching: boolean;
    isError: boolean;
    errorMessage: string;
    hasMore: boolean;
  };
  /** 커뮤니티 게시판 리스트와 카운트  */
  communityBoardsWithCount: BoardCount[];
  /** 커뮤니티 검색 결과 */
  communitySearchResults: {
    items: CommunitySearchDocument[];
    pivotId: number | null;
    pivotScore: number | null;
    totalCount: number;
    isFetching: boolean;
    isError: boolean;
    errorMessage: string;
    hasMore: boolean;
  };
  communityContentsSelections: {
    boardId: number;
    orderType: SearchOrderType;
  };
  lastSearchKeyword: string;
}

export const useSearchStore = defineStore('search-store', {
  state: (): State => ({
    popularSearches: [],
    productSearchResults: {
      items: [],
      pageNo: 1,
      totalCount: 0,
      isFetching: false,
      isError: false,
      errorMessage: '',
      hasMore: true,
    },
    nicknameSearchResults: {
      items: [],
      pageNo: 1,
      totalCount: 0,
      isFetching: false,
      isError: false,
      errorMessage: '',
      hasMore: true,
    },
    communitySearchResults: {
      items: [],
      pivotId: null,
      pivotScore: null,
      totalCount: 0,
      isFetching: false,
      isError: false,
      errorMessage: '',
      hasMore: true,
    },
    communityBoardsWithCount: [],
    communityContentsSelections: {
      boardId: 0, // 0 === all
      orderType: SearchOrderType.Accuracy,
    },
    lastSearchKeyword: '',
  }),
  persist: false,
  actions: {
    async fetchPopularSearches(pagingForm: PaginationOptions) {
      const { data } = await searchService.fetchSearchPopularKeywords(pagingForm);
      this.popularSearches = data.data.items;
    },
    async fetchProductSearchResults({ keyword, pageSize }: { keyword: string; pageSize: number }) {
      this.productSearchResults.isFetching = true;
      try {
        const { data } = await userGnbService.searchClassList(encodeURIComponent(keyword as string), {
          orderColumn: '',
          orderType: '',
          pageSize,
          pageNo: this.productSearchResults.pageNo,
        });
        this.productSearchResults.items = [...this.productSearchResults.items, ...data.items];
        this.productSearchResults.hasMore = data.paging.hasMore;
        this.productSearchResults.totalCount = this.productSearchResults.items.length;
      } catch (err) {
        this.productSearchResults.isError = true;
        if (err.response?._data?.status?.errorMessage && err.status !== 500) {
          this.productSearchResults.errorMessage = err.response._data.status.errorMessage;
        } else {
          this.productSearchResults.errorMessage = '데이터를 불러오지 못했습니다.';
        }
      } finally {
        this.productSearchResults.isFetching = false;
      }
    },
    async fetchNicknameSearchResults({ keyword, pageSize }: { keyword: string; pageSize: number }) {
      try {
        this.nicknameSearchResults.isFetching = true;
        const { data } = await userGnbService.searchNicknameList(encodeURIComponent(keyword as string), {
          orderColumn: '',
          orderType: '',
          pageSize,
          pageNo: this.nicknameSearchResults.pageNo,
        });
        this.nicknameSearchResults.items = [...this.nicknameSearchResults.items, ...data.items];
        this.nicknameSearchResults.hasMore = data.paging.hasMore;
        this.nicknameSearchResults.totalCount = data.paging.totalCount;
      } catch (err) {
        this.nicknameSearchResults.isError = true;
        if (err.response?._data?.status?.errorMessage && err.status !== 500) {
          this.nicknameSearchResults.errorMessage = err.response._data.status.errorMessage;
        } else {
          this.nicknameSearchResults.errorMessage = '데이터를 불러오지 못했습니다.';
        }
      } finally {
        this.nicknameSearchResults.isFetching = false;
      }
    },
    async fetchSearchCommunitiesContentsCount(keyword: string) {
      try {
        const { data } = await searchService.fetchSearchCommunitiesContentsCount(keyword);
        this.communityBoardsWithCount = data.data.items.boardCounts ?? [];
      } catch (e) {
        this.communityBoardsWithCount = [];
      }
    },
    async fetchSearchCommunityContents({ keyword, pageSize }: { keyword: string; pageSize: number }) {
      if (this.communitySearchResults.hasMore === false) {
        return;
      }
      try {
        this.communitySearchResults.isFetching = true;
        const { data } = await searchService.fetchSearchCommunities({
          keyword,
          pageSize,
          boardId: this.communityContentsSelections.boardId || null,
          orderType: this.communityContentsSelections.orderType,
          pivotId: this.communitySearchResults.pivotId,
          pivotScore: this.communitySearchResults.pivotScore,
        });
        const items = data.data.items ?? [];
        this.lastSearchKeyword = keyword;
        this.communitySearchResults = {
          ...this.communitySearchResults,
          items: [...this.communitySearchResults.items, ...items],
          hasMore: items.length === pageSize,
          totalCount: data.data.paging.totalCount,
          pivotId: data.data.paging.pivotId,
          pivotScore: data.data.paging.pivotScore,
          isError: false,
        };
      } catch (err) {
        this.communitySearchResults.isError = true;
        if (err.response?._data?.status?.errorMessage && err.status !== 500) {
          this.communitySearchResults.errorMessage = err.response._data.status.errorMessage;
        } else {
          this.communitySearchResults.errorMessage = '데이터를 불러오지 못했습니다.';
        }
      } finally {
        this.communitySearchResults.isFetching = false;
      }
    },
    resetProductSearchResults() {
      this.productSearchResults = {
        items: [],
        pageNo: 1,
        totalCount: 0,
        isFetching: false,
        isError: false,
        errorMessage: '',
        hasMore: true,
      };
    },
    resetSearchCommunityResults() {
      this.communitySearchResults = {
        items: [],
        pivotId: null,
        pivotScore: null,
        totalCount: 0,
        isFetching: false,
        isError: false,
        errorMessage: '',
        hasMore: true,
      };
    },
    resetSearchNicknameResults() {
      this.nicknameSearchResults = {
        items: [],
        pageNo: 1,
        totalCount: 0,
        isFetching: false,
        isError: false,
        errorMessage: '',
        hasMore: true,
      };
    },
    resetSearchCommunitySelections() {
      this.communityContentsSelections = {
        boardId: 0,
        orderType: SearchOrderType.Accuracy,
      };
    },
    setCommunityContentsSelections(params: Partial<State['communityContentsSelections']>) {
      this.communityContentsSelections = { ...this.communityContentsSelections, ...params };
    },
  },
  getters: {
    getPopularSearches(state) {
      return state.popularSearches;
    },
  },
});
