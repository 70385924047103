<script setup lang="ts">
import MagnifierIcon from '~/components/common/Icons/MagnifierIcon.vue';
import XCircleIcon from '~/components/common/Icons/XCircleIcon.vue';

defineProps<{ value: string; autofocus?: boolean }>();
const emit = defineEmits<{ (e: 'update:value', param: any): void; (e: 'searchSubmit', param: any) }>();
const searchInputRef = ref<HTMLInputElement | null>(null);
defineExpose({
  searchInputRef,
});
</script>

<template>
  <span class="inline-flex h-12 md:h-10.5 relative w-full md:w-94">
    <span class="h-full pl-4 flex items-center absolute">
      <MagnifierIcon
        class="w-5 h-5 md:w-4 md:h-4"
        :class="{ 'text-neutral-500': !value, 'text-primary-900': !!value }" />
    </span>
    <InputText
      ref="searchInputRef"
      :value="value"
      type="search"
      name="q"
      placeholder="검색어를 입력하세요"
      autocomplete="off"
      :autofocus="autofocus"
      class="search-input rounded md:rounded-lg font-medium focus:border focus:border-primary-900 focus:bg-white py-0 px-11 md:pl-10 h-full w-full text-base md:text-sm"
      :class="{ 'px-11 border border-primary-900 bg-white': value, 'pl-11': !value }"
      @input="($event) => emit('update:value', $event.target.value)"
      @keyup.enter="($event) => emit('searchSubmit', $event.target.value)" />
    <XCircleIcon
      v-if="value"
      class="absolute right-3 md:right-4 top-1/2 -translate-y-1/2 w-6 h-6 md:w-5 md:h-5 shrink-0 cursor-pointer"
      @click.stop="emit('update:value', '')" />
  </span>
</template>

<style scoped>
.search-input::-webkit-search-decoration,
.search-input::-webkit-search-cancel-button,
.search-input::-webkit-search-results-button,
.search-input::-webkit-search-results-decoration {
  display: none;
}
</style>
